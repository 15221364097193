import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../layout'
import SEO from '../seo'

import './article.scss'

const Article = ({ data: { directusArtigo: article } }) => {
    const published_date = new Date(article.created_on)

    function createMarkup() {
        return {__html: article.conteudo}
    }

    return(
        <Layout>
            <SEO title="Blog" />

            <article className="blog-article">
                <div class="blog-article__container">
                    <p className="blog-article__back">
                        <Link to="/blog">Voltar aos artigos</Link></p>

                    <h1>{ article.titulo }</h1>
                    <div className="blog-article__subline">
                        <p>Escrito por {article.owner.first_name} {article.owner.last_name}</p> 
                        <p>Publicado em { published_date.toLocaleDateString('pt-BR') }</p>
                    </div>

                    <div className="blog-article__content" dangerouslySetInnerHTML={createMarkup()} />
                </div>
            </article> 
        </Layout>
    )
}

export const query = graphql`
  query ArticleTemplate($id: String!) {
    directusArtigo(id: { eq: $id }) {
        titulo
        conteudo
        owner {
          first_name
          last_name
        }
        id
        created_on
    }
  }
`
  
export default Article
